// frontend/src/hooks/useWebSocket.js

import { useEffect, useRef, useCallback, useState } from 'react';
import { BASE_URL } from '../api/axiosConfig';
import { axiosInstance } from '../api/axiosConfig';

const useWebSocket = (userId, onMessage, token) => {
  const ws = useRef(null);
  const pingInterval = useRef(null);
  const reconnectAttempts = useRef(0);
  const maxReconnectAttempts = 5;
  const reconnectTimeoutRef = useRef(null);
  const isConnecting = useRef(false);
  const [status, setStatus] = useState({
    initialProcessingComplete: false,
    fullSummaryCreated: false,
    summarizationActive: false,
    processingError: null,
    lastStatusUpdate: null
  });

  const handleWebSocketMessage = useCallback((message) => {
    console.log('Received WebSocket message:', message);

    if (message.event === 'full_summary_complete' && message.success) {
      console.log('Setting status from full_summary_complete event');
      setStatus(prev => {
        const newStatus = {
          ...prev,
          fullSummaryCreated: true,
          summarizationActive: false,
          processingError: null
        };
        console.log('New status after full_summary_complete:', newStatus);
        return newStatus;
      });
    } else if (message.event === 'status_update') {
      console.log('Setting status from status_update event');
      setStatus(prev => {
        const newStatus = {
          ...prev,
          initialProcessingComplete: message.initial_processing_complete,
          summarizationActive: message.summarization_active,
          fullSummaryCreated: message.full_summary_created,
          processingError: message.processing_error,
          lastStatusUpdate: message.last_status_update ? new Date(message.last_status_update) : null
        };
        console.log('New status after status_update:', newStatus);
        return newStatus;
      });
    }
    onMessage?.(message);
  }, [onMessage]);

  const connect = useCallback(() => {
    if (!userId || !token || isConnecting.current || ws.current?.readyState === WebSocket.OPEN) return;

    isConnecting.current = true;
    console.log('Connecting WebSocket...');

    const url = new URL(BASE_URL);
    const protocol = url.protocol === 'https:' ? 'wss:' : 'ws:';
    const wsUrl = `${protocol}//${url.host}/ws/status/${userId}?token=${encodeURIComponent(token)}`;

    if (ws.current) {
      ws.current.close();
      ws.current = null;
    }

    ws.current = new WebSocket(wsUrl);

    ws.current.onopen = () => {
      console.log('WebSocket connected');
      isConnecting.current = false;
      reconnectAttempts.current = 0;

      if (pingInterval.current) {
        clearInterval(pingInterval.current);
      }

      pingInterval.current = setInterval(() => {
        if (ws.current?.readyState === WebSocket.OPEN) {
          ws.current.send('ping');
        }
      }, 30000);
    };

    ws.current.onmessage = (event) => {
      if (event.data === 'ping' || event.data === 'pong') {
        return;
      }

      if (!event.data) {
        console.warn('Received empty WebSocket message');
        return;
      }

      try {
        const data = JSON.parse(event.data);
        console.log('Received WebSocket message:', data);
        if (data && typeof data === 'object') {
          handleWebSocketMessage(data);
        } else {
          console.warn('Received non-object WebSocket message:', data);
        }
      } catch (error) {
        console.error('Error parsing WebSocket message:', error, 'Raw message:', event.data);
      }
    };

    ws.current.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    ws.current.onclose = (event) => {
      console.log('WebSocket disconnected:', event);
      isConnecting.current = false;
      clearInterval(pingInterval.current);

      if (reconnectAttempts.current < maxReconnectAttempts) {
        const timeout = Math.min(1000 * Math.pow(2, reconnectAttempts.current), 30000);
        reconnectAttempts.current++;
        console.log(`Reconnecting in ${timeout}ms (attempt ${reconnectAttempts.current})`);

        reconnectTimeoutRef.current = setTimeout(connect, timeout);
      }
    };
  }, [userId, token, handleWebSocketMessage]);

  const sendMessage = useCallback((message) => {
    if (ws.current?.readyState === WebSocket.OPEN) {
      ws.current.send(message);
    }
  }, []);

  const executeNextAction = useCallback(async () => {
    if (!userId || !token || status.summarizationActive || status.fullSummaryCreated) {
      console.log('Skipping executeNextAction:', { 
        noUser: !userId, 
        noToken: !token, 
        isActive: status.summarizationActive, 
        hasFullSummary: status.fullSummaryCreated 
      });
      return;
    }

    try {
      console.log('Initiating full summary generation...');
      await axiosInstance.post(`/summaries/${userId}/full`);
      setStatus(prev => ({ 
        ...prev, 
        summarizationActive: true
      }));
    } catch (error) {
      console.error('Error executing next action:', error);
      if (error.response?.status === 400 && error.response?.data?.detail?.includes('already in progress')) {
        setStatus(prev => ({ 
          ...prev, 
          summarizationActive: true
        }));
      } else {
        setStatus(prev => ({ 
          ...prev, 
          summarizationActive: false,
          processingError: error.message 
        }));
      }
    }
  }, [status.summarizationActive, status.fullSummaryCreated, userId, token]);

  // Connection effect
  useEffect(() => {
    if (userId && token) {
      connect();
    }
    return () => {
      if (ws.current) {
        ws.current.close();
        ws.current = null;
      }
    };
  }, [userId, token, connect]);

  // Polling effect
  useEffect(() => {
    if (!userId || !token) return;

    const getPollInterval = () => {
      return status.summarizationActive ? 2000 : 5000;
    };

    const interval = setInterval(() => {
      sendMessage('get_status');
    }, getPollInterval());

    return () => clearInterval(interval);
  }, [userId, token, sendMessage, status.summarizationActive]);

  // Cleanup effect
  useEffect(() => {
    return () => {
      console.log('Cleaning up WebSocket connection');
      clearInterval(pingInterval.current);
      clearTimeout(reconnectTimeoutRef.current);
      if (ws.current) {
        ws.current.close();
        ws.current = null;
      }
      isConnecting.current = false;
    };
  }, []);

  return {
    status,
    sendMessage,
    executeNextAction
  };
};

export default useWebSocket;
