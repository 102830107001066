import { useState, useEffect } from 'react';
import { axiosInstance, chatAxiosInstance } from '../api/axiosConfig';

const useAuth = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  // Helper function to set auth token in both axios instances
  const setAuthToken = (token) => {
    if (token) {
      axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      chatAxiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
      delete axiosInstance.defaults.headers.common['Authorization'];
      delete chatAxiosInstance.defaults.headers.common['Authorization'];
    }
  };

  // Load user data on mount
  useEffect(() => {
    const loadUser = async () => {
      try {
        const storedUser = localStorage.getItem('user');
        if (storedUser) {
          const userData = JSON.parse(storedUser);
          // Validate the token
          setAuthToken(userData.access_token);
          await axiosInstance.get('/users/me');
          setUser(userData);
        }
      } catch (error) {
        console.error('Error loading user:', error);
        // Clear invalid data
        localStorage.removeItem('user');
        setAuthToken(null);
      } finally {
        setLoading(false);
      }
    };

    loadUser();
  }, []);

  const login = async (userData) => {
    if (!userData || !userData.access_token) {
      throw new Error('Invalid login data received');
    }

    console.log('Logging in user:', userData);
    
    try {
      setLoading(true);
      // Set the authorization header
      setAuthToken(userData.access_token);
      
      // Test the token with a request to /users/me
      const response = await axiosInstance.get('/users/me');
      
      // Update user data with HIPAA status from backend
      const updatedUserData = {
        ...userData,
        hipaa_authorized: response.data.hipaa_authorized
      };
      
      // If validation succeeds, set the user
      setUser(updatedUserData);
      localStorage.setItem('user', JSON.stringify(updatedUserData));
    } catch (error) {
      console.error('Login validation failed:', error);
      // Clean up any partial state
      logout();
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const logout = () => {
    setUser(null);
    localStorage.removeItem('user');
    setAuthToken(null);
  };

  return { user, login, logout, loading, setUser };
};

export default useAuth; 