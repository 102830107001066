import React, { useState, useEffect, useRef } from 'react';
import { CircularProgress, Box, Typography, Button } from '@mui/material';
import { axiosInstance } from '../api/axiosConfig';
import { useNavigate } from 'react-router-dom';
import jsPDF from 'jspdf';
import useAuth from '../hooks/useAuth';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Link as MUILink } from '@mui/material';
import { processReferences } from '../utils/processReferences';
import { useTutorial } from '../contexts/TutorialContext';

const MyHealthHistory = () => {
  const { user, loading: authLoading } = useAuth();
  const [fullSummary, setFullSummary] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showScrollTop, setShowScrollTop] = useState(false);
  const { showTooltip } = useTutorial();
  const tooltipRefs = useRef({
    downloadBtn: null,
    referenceLink: null
  });

  const navigate = useNavigate();

  /**
   * Fetches the full summary for the authenticated user.
   */
  useEffect(() => {
    const fetchFullSummary = async () => {
      // Don't try to fetch if auth is still loading
      if (authLoading) {
        return;
      }

      // Only set error if auth has finished loading and we don't have a user
      if (!user || !user.id) {
        console.log('No user or user ID found after auth loaded');
        setError('User not authenticated');
        setLoading(false);
        return;
      }

      try {
        const response = await axiosInstance.get(`/summaries/${user.id}/full`);
        console.log('Summary response:', response.data);
        if (response.data && response.data.summary_text) {
          const processedSummary = processReferences(response.data.summary_text);
          setFullSummary(processedSummary);
          setError(null);
          setLoading(false);
        } else {
          console.log('No summary text in response');
          setError('No full summary available.');
          setLoading(false);
        }
      } catch (err) {
        console.error('Error fetching full summary:', err);
        setError('Failed to load full summary.');
        setLoading(false);
      }
    };

    fetchFullSummary();
  }, [user, authLoading]);

  /**
   * Handles the visibility of the "Scroll to Top" button based on scroll position.
   */
  useEffect(() => {
    const handleScroll = () => {
      setShowScrollTop(window.pageYOffset > 300);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  /**
   * Scrolls the window smoothly to the top.
   */
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  /**
   * Downloads the full summary as a PDF.
   */
  const handleDownload = async () => {
    try {
      const response = await axiosInstance.get(`/summaries/${user.id}/download-pdf`, {
        responseType: 'blob',
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'health_profile.pdf');
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error('Error downloading PDF:', error);
    }
  };

  /**
   * Custom renderer for links in ReactMarkdown.
   * Handles internal reference links by navigating without reloading the page.
   */
  const renderers = {
    a: ({ href, children }) => {
      const referenceRegex = /^\/reference\/(SUMMARY|DATA_CHUNK|ANSWER)\/(\d+)$/;
      const match = href.match(referenceRegex);
      if (match) {
        const [, type, id] = match;
        return (
          <MUILink 
            href="#" 
            onClick={(e) => { 
              e.preventDefault(); 
              navigate(`/reference/${type}/${id}`);
            }}
            color="primary"
            underline="hover"
            sx={{ color: '#2196f3' }}
            ref={(el) => {
              if (!tooltipRefs.current.referenceLink) {
                tooltipRefs.current.referenceLink = el;
              }
            }}
          >
            {children}
          </MUILink>
        );
      }
      return (
        <MUILink href={href} target="_blank" rel="noopener noreferrer">
          {children}
        </MUILink>
      );
    },
  };

  useEffect(() => {
    if (!loading && !error) {
      const timeoutId = setTimeout(() => {
        if (tooltipRefs.current.downloadBtn) {
          showTooltip(
            'download-pdf',
            tooltipRefs.current.downloadBtn,
            'Download your complete health history as a PDF document',
            'top'
          );
        }
        if (tooltipRefs.current.referenceLink) {
          showTooltip(
            'reference-link',
            tooltipRefs.current.referenceLink,
            'Click to see the source of this information',
            'top'
          );
        }
      }, 2000);

      return () => clearTimeout(timeoutId);
    }
  }, [loading, error, showTooltip]);

  if (authLoading || loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="60vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box p={4}>
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </Box>
    );
  }

  return (
    <Box p={4}>
      <Typography variant="h4" gutterBottom>
        My Health History
      </Typography>
      {fullSummary ? (
        <>
          <ReactMarkdown 
            children={fullSummary}
            components={renderers} 
            remarkPlugins={[remarkGfm]}
          />
          <Button 
            variant="contained" 
            color="primary" 
            onClick={handleDownload} 
            sx={{ mt: 2 }}
            ref={node => {
              tooltipRefs.current.downloadBtn = node;
              console.log('Download button ref set:', node);
            }}
          >
            Download Health Profile
          </Button>
        </>
      ) : (
        <Typography>No summary available.</Typography>
      )}
      {showScrollTop && (
        <Button 
          variant="contained" 
          color="secondary" 
          onClick={scrollToTop} 
          sx={{ position: 'fixed', bottom: 20, right: 20 }}
        >
          Scroll to Top
        </Button>
      )}
    </Box>
  );
};

export default MyHealthHistory;