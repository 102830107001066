import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledButton = styled(Button)({
  fontFamily: 'Courier New, Courier, monospace',
  textTransform: 'lowercase',
});

const DemoAccountModal = ({ open, onClose }) => {
  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      PaperProps={{
        sx: {
          fontFamily: 'Courier New, Courier, monospace',
          '& .MuiTypography-root': {
            textTransform: 'lowercase'
          }
        }
      }}
    >
      <DialogTitle sx={{ textTransform: 'lowercase' }}>demo account notice</DialogTitle>
      <DialogContent>
        <Typography sx={{ textTransform: 'lowercase' }}>
          you are currently using a demo account. this means:
          <ul>
            <li>all data is simulated for demonstration purposes</li>
            <li>no real patient information is being used</li>
            <li>features and functionality may be limited</li>
          </ul>
        </Typography>
      </DialogContent>
      <DialogActions>
        <StyledButton onClick={onClose} variant="contained">
          i understand
        </StyledButton>
      </DialogActions>
    </Dialog>
  );
};

export default DemoAccountModal;