// src/index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import { TutorialProvider } from './contexts/TutorialContext';
import { DemoProvider } from './contexts/DemoContext';
import { GoogleOAuthProvider } from '@react-oauth/google';

// For debugging - remove in production
console.log('Google Client ID:', process.env.REACT_APP_GOOGLE_CLIENT_ID);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
    <DemoProvider>
      <TutorialProvider>
        <Router>
          <App />
        </Router>
      </TutorialProvider>
    </DemoProvider>
  </GoogleOAuthProvider>
);
