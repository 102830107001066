import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Box, Paper, Typography, TextField, Button, IconButton, Container, Link } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import RefreshIcon from '@mui/icons-material/Refresh';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import useAuth from '../hooks/useAuth';
import HipaaAuthModal from './HipaaAuthModal';
import { axiosInstance, chatAxiosInstance } from '../api/axiosConfig';
import { useChat } from '../contexts/ChatContext';

const OrinChat = () => {
  const { user, setUser } = useAuth();
  const { 
    chatHistory, 
    setChatHistory, 
    processingStarted,
    setProcessingStarted 
  } = useChat();
  const [message, setMessage] = useState('');
  const [showHipaaModal, setShowHipaaModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const messagesEndRef = useRef(null);
  const pollInterval = useRef(null);

  const scrollToBottom = useCallback(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [chatHistory, scrollToBottom]);

  // Helper function to add random delay
  const addMessageWithDelay = useCallback(async (message) => {
    const delay = Math.random() * 1500 + 500;
    await new Promise(resolve => setTimeout(resolve, delay));
    setChatHistory(prev => [...prev, message]);
  }, [setChatHistory]);

  // Initialize chat when user logs in or HIPAA status changes
  useEffect(() => {
    const initializeChat = async () => {
      if (!user) return;

      // Only initialize if chat history is empty
      if (chatHistory.length === 0) {
        try {
          const response = await axiosInstance.get('/users/me');
          const isAuthorized = response.data.hipaa_authorized || false;
          
          // Update user state if HIPAA status has changed
          if (isAuthorized !== user.hipaa_authorized) {
            const updatedUser = {
              ...user,
              hipaa_authorized: isAuthorized
            };
            localStorage.setItem('user', JSON.stringify(updatedUser));
            setUser(updatedUser);
          }
          
          // Set initial message based on authorization status
          if (!user.hipaa_authorized && !isAuthorized) {
            setChatHistory([
              { 
                text: "Welcome! Before we begin, please review and accept our HIPAA authorization",
                sender: 'assistant',
                includeLink: true
              }
            ]);
          } else {
            setChatHistory([
              { 
                text: "Welcome to Orin! I'm here to help you understand your medical records and answer any health-related questions you may have. Feel free to ask me anything about your medical history, medications, or general health questions.",
                sender: 'assistant'
              }
            ]);
          }
        } catch (error) {
          console.error('Error checking HIPAA status:', error);
          // If there's a timeout or other error, use the user's stored HIPAA status
          if (user.hipaa_authorized) {
            setChatHistory([
              { 
                text: "Welcome to Orin! I'm here to help you understand your medical records and answer any health-related questions you may have. Feel free to ask me anything about your medical history, medications, or general health questions.",
                sender: 'assistant'
              }
            ]);
          } else {
            setChatHistory([
              { 
                text: "Welcome! Before we begin, please review and accept our HIPAA authorization",
                sender: 'assistant',
                includeLink: true
              }
            ]);
          }
        }
      }
    };

    initializeChat();
  }, [user, chatHistory.length, setChatHistory, setUser]);

  // Add handler for email notification
  const handleEmailNotification = async () => {
    try {
      await axiosInstance.post('/auth/update-email-notification');
      setChatHistory(prev => [...prev, {
        text: "I'll send you an email when your medical records analysis is complete. You can continue chatting with me in the meantime.",
        sender: 'assistant'
      }]);
    } catch (error) {
      console.error('Error updating email notification:', error);
      setChatHistory(prev => [...prev, {
        text: "I apologize, but I couldn't set up the email notification. Please try again.",
        sender: 'assistant'
      }]);
    }
  };

  const handleHipaaAuthorize = async (signatureData) => {
    setShowHipaaModal(false);
    
    const initialMessages = [
      { text: "Thank you for accepting the HIPAA authorization.", sender: 'assistant' }
    ];

    // Check if demo mode is enabled
    try {
      const response = await axiosInstance.get('/config');
      const config = response.data;
      
      if (config.ORIN_DEMO) {
        initialMessages.push({
          text: "THIS IS A DEMO account using DEMO data of a random individual. This is not your data and is for demo purposes only.",
          sender: 'assistant'
        });
      }
    } catch (error) {
      console.error('Error checking demo status:', error);
    }

    initialMessages.push({
      text: "I'm now analyzing your medical records to build a comprehensive understanding of your health history. This typically takes 3-5 minutes, as I carefully review your records to ensure accuracy. Would you like me to notify you by email when the analysis is complete?",
      sender: 'assistant',
      showEmailOption: true
    });

    // Add messages one by one with delays
    for (const msg of initialMessages) {
      await addMessageWithDelay(msg);
    }
    setProcessingStarted(true);

    // Initiate processing on the backend
    try {
      await axiosInstance.post('/summaries/initiate-summarization');
    } catch (error) {
      console.error('Error initiating summarization:', error);
    }
  };

  // Add polling for summary status
  useEffect(() => {
    if (!user || !processingStarted) return;

    const checkSummaryStatus = async () => {
      try {
        const response = await axiosInstance.get('/users/me');
        const { summarization_active, full_summary_created, processing_error } = response.data;

        if (processing_error) {
          await addMessageWithDelay({
            text: "I apologize, but there was an issue generating your health summary. Please try refreshing the page or contact support if the issue persists.",
            sender: 'assistant'
          });
          clearInterval(pollInterval.current);
          setProcessingStarted(false);
        } else if (!summarization_active && full_summary_created) {
          await addMessageWithDelay({
            text: "Great news! Your comprehensive Health History is now ready. You can access it through the 'Health History' tab in the side panel. The summary provides an organized overview of your medical history by time periods and categories. Feel free to ask me any questions about your health history!",
            sender: 'assistant'
          });
          clearInterval(pollInterval.current);
          setProcessingStarted(false);
        }
      } catch (error) {
        console.error('Error checking summary status:', error);
      }
    };

    // Poll every 5 seconds
    pollInterval.current = setInterval(checkSummaryStatus, 5000);

    return () => {
      if (pollInterval.current) {
        clearInterval(pollInterval.current);
      }
    };
  }, [user, processingStarted, addMessageWithDelay, setProcessingStarted]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!message.trim()) return;

    // Add user message to chat
    setChatHistory(prev => [...prev, { text: message, sender: 'user' }]);
    const userMessage = message;
    setMessage('');
    setIsLoading(true);

    try {
      const payload = {
        message: userMessage,
        is_end: false
      };

      const response = await chatAxiosInstance.post('/chat', payload, {
        responseType: 'stream'
      });

      const reader = response.data.getReader();
      const decoder = new TextDecoder();
      let assistantMessage = '';

      const processChunk = async (chunk) => {
        const text = decoder.decode(chunk || new Uint8Array(), { stream: true });
        const lines = text.split('\n\n');

        for (const line of lines) {
          if (line.trim() === '') continue;
          if (line.startsWith('data: ')) {
            const data = line.replace('data: ', '');
            if (data.startsWith('Error:')) {
              throw new Error(data);
            }
            assistantMessage += data;
            setChatHistory(prev => {
              const updated = [...prev];
              if (updated[updated.length - 1]?.sender === 'assistant') {
                updated[updated.length - 1] = { text: assistantMessage, sender: 'assistant' };
              } else {
                updated.push({ text: assistantMessage, sender: 'assistant' });
              }
              return updated;
            });
          }
        }
      };

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        await processChunk(value);
      }
    } catch (error) {
      console.error('Error sending message:', error);
      setChatHistory(prev => [...prev, { 
        text: "I apologize, but I'm having trouble connecting to the chat service. Please try again.", 
        sender: 'assistant' 
      }]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleResetChat = async () => {
    try {
      await chatAxiosInstance.post('/reset');

      // Only show HIPAA message if user is not authorized
      if (!user.hipaa_authorized) {
        setChatHistory([
          { 
            text: "Welcome! Before we begin, please review and accept our HIPAA authorization",
            sender: 'assistant',
            includeLink: true,
            feedback: null 
          }
        ]);
      } else {
        // Show regular welcome message for authorized users
        setChatHistory([
          { 
            text: "Welcome to Orin! I'm here to help you understand your medical records and answer any health-related questions you may have. Feel free to ask me anything about your medical history, medications, or general health questions.",
            sender: 'assistant',
            feedback: null 
          }
        ]);
      }
    } catch (error) {
      console.error('Error resetting chat:', error);
    }
  };

  const handleFeedback = (index, type) => {
    setChatHistory(prev => {
      const updated = [...prev];
      updated[index] = {
        ...updated[index],
        feedback: type
      };
      return updated;
    });
  };

  return (
    <Box sx={{ mt: 4, height: '60vh', display: 'flex', flexDirection: 'column' }}>
      <Paper 
        elevation={3} 
        sx={{ 
          flex: 1, 
          mb: 2, 
          p: 2, 
          overflow: 'auto',
          bgcolor: '#f5f5f5'
        }}
      >
        {chatHistory.map((msg, index) => (
          <Box
            key={index}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: msg.sender === 'user' ? 'flex-end' : 'flex-start',
              mb: 1,
            }}
          >
            <Paper
              sx={{
                p: 1,
                maxWidth: '70%',
                bgcolor: msg.sender === 'user' ? 'primary.main' : 'white',
                color: msg.sender === 'user' ? 'white' : 'text.primary',
                position: 'relative',
              }}
            >
              <Typography variant="body1">
                {msg.text}
                {msg.includeLink && (
                  <Link
                    component="button"
                    onClick={() => setShowHipaaModal(true)}
                    sx={{ 
                      ml: 1,
                      color: 'primary.main',
                      textDecoration: 'underline',
                      cursor: 'pointer',
                      fontFamily: 'Courier New, Courier, monospace'
                    }}
                  >
                    here
                  </Link>
                )}
              </Typography>
              {msg.sender === 'assistant' && (
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1, gap: 1 }}>
                  {msg.showEmailOption && (
                    <Button
                      size="small"
                      variant="outlined"
                      onClick={handleEmailNotification}
                      sx={{
                        fontFamily: 'Courier New, Courier, monospace',
                        textTransform: 'none'
                      }}
                    >
                      Notify me by email
                    </Button>
                  )}
                  <IconButton 
                    size="small" 
                    onClick={() => handleFeedback(index, 'up')}
                    color={msg.feedback === 'up' ? 'primary' : 'default'}
                  >
                    <ThumbUpIcon fontSize="small" />
                  </IconButton>
                  <IconButton 
                    size="small" 
                    onClick={() => handleFeedback(index, 'down')}
                    color={msg.feedback === 'down' ? 'primary' : 'default'}
                  >
                    <ThumbDownIcon fontSize="small" />
                  </IconButton>
                </Box>
              )}
            </Paper>
          </Box>
        ))}
        <div ref={messagesEndRef} />
      </Paper>
      <form onSubmit={handleSubmit} style={{ display: 'flex', gap: '8px' }}>
        <TextField
          fullWidth
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Type your message..."
          variant="outlined"
          size="small"
          disabled={isLoading}
        />
        <Button 
          type="submit" 
          variant="contained" 
          endIcon={<SendIcon />}
          disabled={isLoading}
        >
          {isLoading ? 'Sending...' : 'Send'}
        </Button>
        <IconButton
          color="primary"
          onClick={handleResetChat}
          disabled={isLoading}
          title="Reset Chat"
        >
          <RefreshIcon />
        </IconButton>
      </form>

      <HipaaAuthModal
        open={showHipaaModal}
        onClose={() => setShowHipaaModal(false)}
        onAuthorize={handleHipaaAuthorize}
      />
    </Box>
  );
};

export default OrinChat; 