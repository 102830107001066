import React, { useState, useEffect } from 'react';
import { Box, Button, Container, Typography, CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { axiosInstance } from '../api/axiosConfig';
import RegisterModal from './RegisterModal';
import GoogleLoginButton from './GoogleLoginButton';
import { useNavigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import hipaaLogo from '../assets/hipaa.png';

const StyledButton = styled(Button)({
  fontFamily: 'Courier New, Courier, monospace',
  border: '2px solid #333',
  color: '#333',
  padding: '10px 30px',
  margin: '10px',
  textTransform: 'lowercase',
  '&:hover': {
    backgroundColor: '#333',
    color: '#fff',
    border: '2px solid #333',
  },
});

const StyledTextField = styled(TextField)({
  '& .MuiInputBase-root': {
    fontFamily: 'Courier New, Courier, monospace',
    textTransform: 'lowercase'
  },
  '& .MuiInputLabel-root': {
    fontFamily: 'Courier New, Courier, monospace',
    textTransform: 'lowercase'
  },
  marginBottom: '1rem',
});

const TypingText = ({ text, onComplete }) => {
  const [displayText, setDisplayText] = useState('');
  const [wordIndex, setWordIndex] = useState(0);
  // Store words in a ref to prevent re-splitting on each render
  const wordsRef = React.useRef(text.split(' '));

  useEffect(() => {
    const words = wordsRef.current;
    let isCurrentlyTyping = true;

    const typeWord = async () => {
      if (wordIndex < words.length && isCurrentlyTyping) {
        // Build the complete text up to the current word
        const currentText = words.slice(0, wordIndex + 1).join(' ');
        
        // More natural typing delays based on word type
        const currentWord = words[wordIndex];
        const commonWords = new Set(['I', 'am', 'a', 'to', 'and', 'the', 'of', 'in', 'your']);
        const keyTerms = new Set(['medical', 'assistant', 'health', 'wellness', 'understand', 'designed']);
        
        let baseDelay;
        if (commonWords.has(currentWord)) {
          baseDelay = Math.random() * 10 + 20; // 100-300ms for common words
        } else if (keyTerms.has(currentWord)) {
          baseDelay = Math.random() * 200 + 100; // 700-1500ms for key terms
        } else {
          baseDelay = Math.random() * 100 + 50; // 300-700ms for other words
        }

        // Add "thinking" pauses
        const hasPunctuation = /[.,!?]$/.test(currentWord);
        const isEndOfClause = hasPunctuation || wordIndex === words.length - 1;
        const pauseTime = isEndOfClause ? 500 : 
                         keyTerms.has(currentWord) ? 300 :
                         100;

        await new Promise(resolve => setTimeout(resolve, baseDelay));
        if (isCurrentlyTyping) {
          setDisplayText(currentText);
        }
        
        await new Promise(resolve => setTimeout(resolve, pauseTime));
        if (isCurrentlyTyping) {
          setWordIndex(prev => prev + 1);
        }
      } else if (onComplete && isCurrentlyTyping) {
        // Add final pause before showing buttons
        await new Promise(resolve => setTimeout(resolve, 1000));
        onComplete();
      }
    };

    typeWord();

    // Cleanup function to prevent state updates if component unmounts
    return () => {
      isCurrentlyTyping = false;
    };
  }, [wordIndex, onComplete]);

  return (
    <Typography
      variant="h5"
      component="div"
      sx={{
        fontFamily: 'Courier New, Courier, monospace',
        whiteSpace: 'pre-wrap',
        minHeight: '3em',
        fontWeight: 'bold',
        '&::after': {
          content: '"|"',
          animation: 'blink 1s step-end infinite',
          fontWeight: 'normal',
        },
        '@keyframes blink': {
          'from, to': { opacity: 1 },
          '50%': { opacity: 0 },
        },
      }}
    >
      {displayText}
    </Typography>
  );
};

const LoginModal = ({ open, onClose, onLoginSuccess }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');

    try {
      const formData = new URLSearchParams();
      formData.append('username', email);
      formData.append('password', password);
      formData.append('grant_type', 'password');

      const response = await axiosInstance.post('/auth/login', 
        formData.toString(),
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }
      );
      
      if (response.data && response.data.access_token) {
        const userData = {
          id: response.data.user_id,
          email: email,
          access_token: response.data.access_token
        };
        onLoginSuccess(userData);
        onClose();
      } else {
        setError('invalid login response. please try again.');
      }
    } catch (err) {
      console.error('Login error:', err);
      if (err.response?.status === 422) {
        // Handle FastAPI validation errors
        const validationError = err.response?.data?.detail?.[0]?.msg;
        setError(validationError?.toLowerCase() || 'invalid login format. please check your entries.');
      } else {
        setError(err.response?.data?.detail?.toLowerCase() || 'login failed. please try again.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      PaperProps={{
        sx: {
          fontFamily: 'Courier New, Courier, monospace',
          minWidth: '400px',
          '& .MuiTypography-root': {
            textTransform: 'lowercase'
          }
        }
      }}
    >
      <DialogTitle sx={{ textAlign: 'center', fontWeight: 'bold', textTransform: 'lowercase' }}>
        login
      </DialogTitle>
      <DialogContent>
        <Box component="form" onSubmit={handleLogin} sx={{ mt: 2 }}>
          {error && (
            <Typography color="error" sx={{ mb: 2, textTransform: 'lowercase' }}>
              {error}
            </Typography>
          )}
          <GoogleLoginButton onLoginSuccess={onLoginSuccess} />
          <Box sx={{ my: 2, textAlign: 'center' }}>
            <Typography variant="body2" sx={{ textTransform: 'lowercase' }}>- or -</Typography>
          </Box>
          <StyledTextField
            fullWidth
            label="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            InputLabelProps={{
              sx: { textTransform: 'lowercase' }
            }}
          />
          <StyledTextField
            fullWidth
            label="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            InputLabelProps={{
              sx: { textTransform: 'lowercase' }
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', p: 3 }}>
        <StyledButton onClick={onClose} disabled={isLoading}>
          cancel
        </StyledButton>
        <StyledButton 
          onClick={handleLogin}
          disabled={isLoading}
        >
          {isLoading ? <CircularProgress size={20} /> : 'login'}
        </StyledButton>
      </DialogActions>
    </Dialog>
  );
};

const LandingPage = ({ onLogin }) => {
  const { user, login } = useAuth();
  const navigate = useNavigate();
  const [showButtons, setShowButtons] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const welcomeText = "welcome to orin.health";

  // If user is already set in state on mount, skip the landing screen
  useEffect(() => {
    if (user) {
      navigate('/chat', { replace: true });
    }
  }, [user, navigate]);

  const handleRegisterSuccess = async (userData) => {
    try {
      // This updates the user context and localStorage
      await login(userData);
      // Because "user" will update soon, we'll navigate in the effect above
      // Or you can do an immediate:
      navigate('/chat', { replace: true });
    } catch (err) {
      console.error('Error during login after registration:', err);
    }
  };

  const handleLoginSuccess = (userData) => {
    onLogin(userData); // or directly call login(userData)
    navigate('/chat', { replace: true });
  };

  return (
    <Container maxWidth="md">
      <Box
        sx={{
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
          textAlign: 'center',
          py: 4,
        }}
      >
        <Paper
          elevation={3}
          sx={{
            p: 6,
            borderRadius: 2,
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
            backdropFilter: 'blur(10px)',
            width: '100%',
            maxWidth: 600,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 4,
            transition: 'all 0.3s ease-in-out',
            '&:hover': {
              elevation: 6,
              transform: 'translateY(-4px)',
            },
            mt: 12,
          }}
        >
          <TypingText 
            text={welcomeText} 
            onComplete={() => setShowButtons(true)} 
          />
          
          <Typography
            variant="subtitle1"
            sx={{
              fontFamily: 'Courier New, Courier, monospace',
              textTransform: 'lowercase',
              opacity: showButtons ? 1 : 0,
              transition: 'opacity 0.5s ease-in',
              mt: 2
            }}
          >
            your front door to healthcare
          </Typography>
          
          {showButtons && (
            <Box
              sx={{
                opacity: 1,
                transition: 'opacity 0.5s ease-in',
                mt: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 2,
              }}
            >
              <Box>
                <StyledButton
                  variant="outlined"
                  onClick={() => setShowLoginModal(true)}
                  className="auth-button"
                  sx={{
                    transition: 'all 0.2s ease-in-out',
                    textTransform: 'lowercase',
                    '&:hover': {
                      transform: 'scale(1.05)',
                    }
                  }}
                >
                  login
                </StyledButton>
                <StyledButton
                  variant="outlined"
                  onClick={() => setShowRegisterModal(true)}
                  className="auth-button"
                  sx={{
                    transition: 'all 0.2s ease-in-out',
                    textTransform: 'lowercase',
                    '&:hover': {
                      transform: 'scale(1.05)',
                    }
                  }}
                >
                  register
                </StyledButton>
              </Box>
            </Box>
          )}
        </Paper>

        <Box
          sx={{
            mt: 4,
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 2,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 4,
            }}
          >
            <Box
              component="img"
              src={hipaaLogo}
              alt="HIPAA Compliant"
              sx={{
                height: 50,
                objectFit: 'contain',
                filter: 'grayscale(100%)',
                opacity: 0.8,
                transition: 'all 0.3s ease-in-out',
                '&:hover': {
                  filter: 'grayscale(0%)',
                  opacity: 1,
                },
              }}
            />
          </Box>
          
          <Typography
            variant="caption"
            sx={{
              fontFamily: 'Courier New, Courier, monospace',
              textTransform: 'lowercase',
              color: 'text.secondary',
            }}
          >
            provider npi: #1760294953
          </Typography>
        </Box>

        <LoginModal
          open={showLoginModal}
          onClose={() => setShowLoginModal(false)}
          onLoginSuccess={handleLoginSuccess}
        />

        <RegisterModal
          open={showRegisterModal}
          onClose={() => setShowRegisterModal(false)}
          onRegisterSuccess={handleRegisterSuccess}
        />
      </Box>
    </Container>
  );
};

export default LandingPage; 