import React, { useState } from 'react';
import { GoogleLogin } from '@react-oauth/google';
import { axiosInstance } from '../api/axiosConfig';
import { useNavigate } from 'react-router-dom';
import { Alert, Snackbar, CircularProgress, Box } from '@mui/material';

const GoogleLoginButton = ({ onLoginSuccess }) => {
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleGoogleSuccess = async (credentialResponse) => {
    setIsLoading(true);

    console.log('Google login success response:', credentialResponse);
    try {
      const response = await axiosInstance.post('/auth/google-login', {
        credential: credentialResponse.credential
      });
      
      if (response.data && response.data.access_token) {
        const userData = {
          id: response.data.user_id,
          email: response.data.email,
          access_token: response.data.access_token,
          is_new_user: response.data.is_new_user
        };
        
        // Call the login success handler
        await onLoginSuccess(userData);
        
        // Navigate to chat
        navigate('/chat');
      }
    } catch (error) {
      console.error('Google login error:', error);
      let errorMessage = 'Failed to log in with Google. Please try again.';
      
      if (error.response) {
        console.error('Error status:', error.response.status);
        console.error('Error details:', error.response.data);
        
        // Handle specific error cases
        switch (error.response.status) {
          case 400:
            if (error.response.data.detail === "Google email not verified") {
              errorMessage = 'Please verify your Google email address first.';
            } else if (error.response.data.detail === "No Google credential provided") {
              errorMessage = 'Google login failed. Please try again.';
            }
            break;
          case 401:
            errorMessage = 'Invalid Google login. Please try again.';
            break;
          case 500:
            errorMessage = 'Server error. Please try again later.';
            break;
          default:
            errorMessage = error.response.data.detail || errorMessage;
        }
      }
      setError(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const handleError = (error) => {
    console.error('Google Login Failed:', error);
    console.log('Full error object:', JSON.stringify(error, null, 2));
    setError('Google login failed. Please try again.');
    setIsLoading(false);
  };

  const handleCloseError = () => {
    setError(null);
  };

  return (
    <Box position="relative" display="inline-block">
      {isLoading && (
        <Box
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          display="flex"
          alignItems="center"
          justifyContent="center"
          bgcolor="rgba(255, 255, 255, 0.8)"
          zIndex={1}
          borderRadius="4px"
        >
          <CircularProgress size={24} />
        </Box>
      )}
      <GoogleLogin
        onSuccess={handleGoogleSuccess}
        onError={handleError}
        useOneTap={false}
        flow="implicit"
        auto_select={false}
        context="signin"
        prompt_parent_id="google-login-button"
        cancel_on_tap_outside={true}
        theme="filled_blue"
        disabled={isLoading}
      />
      <Snackbar 
        open={!!error} 
        autoHideDuration={6000} 
        onClose={handleCloseError}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseError} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
    </Box>

  );
};

export default GoogleLoginButton; 