import React from 'react';
import { Box, Container } from '@mui/material';
import TypingText from './TypingText';

const GoodbyePage = () => {
  return (
    <Container maxWidth="md">
      <Box sx={{ 
        minHeight: '100vh', 
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        p: 3
      }}>
        <TypingText 
          text="until next time..... stay 💪 !"
          delay={50}
          variant="h4"
        />
      </Box>
    </Container>
  );
};

export default GoodbyePage; 