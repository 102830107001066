import React from 'react';
import { Box, Typography } from '@mui/material';
import hipaaLogo from '../assets/hipaa.png';

const Footer = () => {
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 2,
        mt: 'auto',
        pt: 4,
        pb: 2,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 4,
        }}
      >
        <Box
          component="img"
          src={hipaaLogo}
          alt="HIPAA Compliant"
          sx={{
            height: 50,
            objectFit: 'contain',
            filter: 'grayscale(100%)',
            opacity: 0.8,
            transition: 'all 0.3s ease-in-out',
            '&:hover': {
              filter: 'grayscale(0%)',
              opacity: 1,
            },
          }}
        />
      </Box>
      
      <Typography
        variant="caption"
        sx={{
          fontFamily: 'Courier New, Courier, monospace',
          textTransform: 'lowercase',
          color: 'text.secondary',
        }}
      >
        provider npi: #1760294953
      </Typography>
    </Box>
  );
};

export default Footer; 