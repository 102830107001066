import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Typography, CircularProgress, Link as MUILink, Button, Grid, Chip, Divider } from '@mui/material';
import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import { axiosInstance } from '../api/axiosConfig';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { processReferences } from '../utils/processReferences';

/**
 * Formats a date string into a more readable format.
 * @param {string} dateString - The date string to format.
 * @returns {string} - Formatted date string.
 */
const formatDate = (dateString) => {
  return new Date(dateString).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });
};

/**
 * Renders detailed information for DataChunk type references.
 * @param {object} data - The data chunk information.
 * @returns {JSX.Element} - Rendered DataChunk view.
 */
const DataChunkView = ({ data, renderers }) => {
  let parsedData;
  try {
    parsedData = typeof data === 'string' ? JSON.parse(data) : data;
  } catch (e) {
    return (
      <Box sx={{ p: 2 }}>
        <Typography variant="body1">
          <ReactMarkdown components={renderers} remarkPlugins={[remarkGfm]}>
            {processReferences(data)}
          </ReactMarkdown>
        </Typography>
      </Box>
    );
  }

  // Determine if this is a pharmacy record by checking for pharmacy-specific fields
  const isPharmacy = 'ndc11' in parsedData;

  if (isPharmacy) {
    return (
      <Box sx={{ p: 2 }}>
        <Typography variant="h6" gutterBottom>
          Pharmacy Claims for {parsedData.year}
        </Typography>
        
        <Grid container spacing={3}>
          {/* Medication Information */}
          <Grid item xs={12} md={6}>
            <Box sx={{ mb: 3 }}>
              <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 'bold' }}>
                Medication Details
              </Typography>
              <Typography variant="body2">
                NDC: {parsedData.ndc11}
              </Typography>
              <Typography variant="body2">
                Days Supply: {parsedData.total_days_supply}
              </Typography>
              <Typography variant="body2">
                Number of Claims: {parsedData.claim_count}
              </Typography>
            </Box>

            {/* Diagnosis Codes */}
            {parsedData.diagnosis_codes && parsedData.diagnosis_codes.length > 0 && (
              <Box sx={{ mb: 3 }}>
                <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 'bold' }}>
                  Associated Diagnoses
                </Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                  {parsedData.diagnosis_codes.map((code, idx) => (
                    <Chip key={idx} label={code} color="secondary" variant="outlined" size="small" />
                  ))}
                </Box>
              </Box>
            )}
          </Grid>

          {/* Insurance Information */}
          <Grid item xs={12} md={6}>
            <Box sx={{ mb: 3 }}>
              <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 'bold' }}>
                Insurance Details
              </Typography>
              {parsedData.PAYER_NAME && (
                <Typography variant="body2">
                  Insurance: {parsedData.PAYER_NAME}
                </Typography>
              )}
              {parsedData.PBM_PROCESSOR && (
                <Typography variant="body2">
                  PBM: {parsedData.PBM_PROCESSOR}
                </Typography>
              )}
              <Typography variant="body2" sx={{ mt: 1 }}>
                Patient Pay: ${parsedData.total_patient_pay?.toFixed(2) || '0.00'}
              </Typography>
              <Typography variant="body2">
                Plan Pay: ${parsedData.total_plan_pay?.toFixed(2) || '0.00'}
              </Typography>
              <Typography variant="body2">
                Total: ${((parsedData.total_patient_pay || 0) + (parsedData.total_plan_pay || 0)).toFixed(2)}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  }

  // Medical Encounter View
  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h6" gutterBottom>
        Medical Encounter on {formatDate(parsedData.service_from)}
      </Typography>
      
      <Grid container spacing={3}>
        {/* Left Column - Clinical Information */}
        <Grid item xs={12} md={6}>
          {/* Procedures */}
          <Box sx={{ mb: 3 }}>
            <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 'bold' }}>
              Procedures
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
              {parsedData.procedures && parsedData.procedures.length > 0 ? (
                parsedData.procedures.map((proc, idx) => (
                  <Chip key={idx} label={proc} color="primary" variant="outlined" />
                ))
              ) : (
                <Typography variant="body2" color="text.secondary">
                  No procedures recorded
                </Typography>
              )}
            </Box>
          </Box>

          {/* Diagnoses */}
          <Box sx={{ mb: 3 }}>
            <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 'bold' }}>
              Diagnoses
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
              {parsedData.diagnoses && parsedData.diagnoses.length > 0 ? (
                parsedData.diagnoses.map((diag, idx) => (
                  <Chip key={idx} label={diag} color="secondary" variant="outlined" />
                ))
              ) : (
                <Typography variant="body2" color="text.secondary">
                  No diagnoses recorded
                </Typography>
              )}
            </Box>
          </Box>

          {/* Service Details */}
          <Box sx={{ mb: 3 }}>
            <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 'bold' }}>
              Service Details
            </Typography>
            {parsedData.place_of_service && parsedData.place_of_service.length > 0 && (
              <Typography variant="body2">
                Place of Service: {parsedData.place_of_service.join(', ')}
              </Typography>
            )}
            {parsedData.emergency && (
              <Typography variant="body2" color="error">
                Emergency Service
              </Typography>
            )}
            {parsedData.days_between_service_dates > 0 && (
              <Typography variant="body2">
                Duration: {parsedData.days_between_service_dates} days
              </Typography>
            )}
          </Box>
        </Grid>

        {/* Right Column - Administrative Information */}
        <Grid item xs={12} md={6}>
          {/* Provider Information */}
          <Box sx={{ mb: 3 }}>
            <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 'bold' }}>
              Provider Information
            </Typography>
            {parsedData.provider_types && parsedData.provider_types.length > 0 && (
              <Typography variant="body2">
                Provider Type: {parsedData.provider_types.join(', ')}
              </Typography>
            )}
            {parsedData.provider_specialties && parsedData.provider_specialties.length > 0 && (
              <Typography variant="body2">
                Specialties: {parsedData.provider_specialties.join(', ')}
              </Typography>
            )}
            {(!parsedData.provider_types || parsedData.provider_types.length === 0) && 
             (!parsedData.provider_specialties || parsedData.provider_specialties.length === 0) && (
              <Typography variant="body2" color="text.secondary">
                No provider information available
              </Typography>
            )}
          </Box>

          {/* Billing Information */}
          <Box sx={{ mb: 3 }}>
            <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 'bold' }}>
              Billing Information
            </Typography>
            {parsedData.total_line_charge && (
              <Typography variant="body2">
                Total Charges: ${parsedData.total_line_charge.toFixed(2)}
              </Typography>
            )}
            {parsedData.total_billed_amount && (
              <Typography variant="body2">
                Total Billed: ${parsedData.total_billed_amount.toFixed(2)}
              </Typography>
            )}
            {parsedData.PAYER_NAME && (
              <Typography variant="body2">
                Insurance: {parsedData.PAYER_NAME}
              </Typography>
            )}
            {(!parsedData.total_line_charge && !parsedData.total_billed_amount && !parsedData.PAYER_NAME) && (
              <Typography variant="body2" color="text.secondary">
                No billing information available
              </Typography>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

const ReferenceDetail = () => {
  const { type, id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  /**
   * Fetches the reference data based on type and id.
   */
  useEffect(() => {
    const fetchReference = async () => {
      try {
        const response = await axiosInstance.get(`/references/${type}/${id}`);
        setData(response.data);
      } catch (err) {
        console.error('Error fetching reference data:', err);
        setError('Failed to load reference data.');
      } finally {
        setLoading(false);
      }
    };

    fetchReference();
  }, [type, id]);

  /**
   * Custom renderer for links in ReactMarkdown.
   * Handles internal reference links by navigating without reloading the page.
   */
  const renderers = {
    a: ({ href, children }) => {
      const referenceRegex = /^\/reference\/(SUMMARY|DATA_CHUNK|ANSWER)\/(\d+)$/;
      const match = href.match(referenceRegex);
      if (match) {
        const [, refType, refId] = match;
        console.log(`Navigating to type: ${refType}, id: ${refId}`);
        return (
          <MUILink
            href="#"
            onClick={(e) => {
              e.preventDefault();
              navigate(`/reference/${refType}/${refId}`);
            }}
            color="primary"
            underline="hover"
            sx={{ color: '#2196f3' }}
          >
            {children}
          </MUILink>
        );
      }
      return (
        <MUILink href={href} target="_blank" rel="noopener noreferrer">
          {children}
        </MUILink>
      );
    },
  };

  if (loading) {
    return (
      <Box sx={{ mt: 4, textAlign: 'center' }}>
        <CircularProgress />
        <Typography variant="body1" sx={{ mt: 2 }}>
          Loading reference data...
        </Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ mt: 4 }}>
        <Typography variant="h6" color="error">
          {error}
        </Typography>
        <Button 
          variant="contained" 
          sx={{ mt: 2 }} 
          onClick={() => navigate(-1)}
        >
          Go Back
        </Button>
      </Box>
    );
  }

  return (
    <Box sx={{ mt: 4 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <Button
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate(-1)}
          sx={{ mr: 2 }}
        >
          Back
        </Button>
        <Typography variant="h5">
          {type === 'ANSWER' ? 'Answer Detail' :
           type === 'SUMMARY' ? 'Summary Detail' :
           'Data Chunk Detail'}
        </Typography>
      </Box>
      <Box sx={{ bgcolor: '#f5f5f5', p: 2, borderRadius: 2 }}>
        {type === 'DATA_CHUNK' ? (
          <DataChunkView data={data.content} renderers={renderers} />
        ) : type === 'ANSWER' ? (
          <Box>
            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                Question:
              </Typography>
              <Typography variant="body1" sx={{ mb: 2 }}>
                {data.question}
              </Typography>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                Answer:
              </Typography>
              <ReactMarkdown 
                remarkPlugins={[remarkGfm]}
                components={renderers}
              >
                {processReferences(data.content)}
              </ReactMarkdown>
            </Box>
          </Box>
        ) : (
          <ReactMarkdown 
            children={processReferences(data.content)}
            remarkPlugins={[remarkGfm]}
            components={renderers}
          />
        )}
      </Box>
    </Box>
  );
};

export default ReferenceDetail;