import React from 'react';
import { Box, Typography, List, ListItem, ListItemText, Divider, ListItemIcon } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import useWebSocket from '../hooks/useWebSocket';
import useAuth from '../hooks/useAuth';
import {
  Chat as ChatIcon,
  History as HistoryIcon,
  Recommend as RecommendIcon,
  Logout as LogoutIcon
} from '@mui/icons-material';

const UserPanel = ({ user }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { status } = useWebSocket(user?.id, null, user?.access_token);
  const { logout } = useAuth();

  console.log('UserPanel status:', status);
  console.log('User:', user);

  const getStatusMessage = (status) => {
    if (status.processingError) {
      return 'Error generating summary';
    }
    if (!status.initialProcessingComplete) {
      return 'Processing initial data...';
    }
    if (status.summarizationActive) {
      // Check if we're in the initial chunk processing phase
      if (!status.initialProcessingComplete) {
        return 'Processing medical records...';
      }
      // If initial processing is complete but full summary isn't created
      if (!status.fullSummaryCreated) {
        return 'Creating comprehensive health summary...';
      }
      return 'Updating health summary...';
    }
    if (status.lastStatusUpdate) {
      return `Last updated: ${new Date(status.lastStatusUpdate).toLocaleDateString()}`;
    }
    return null;
  };

  const menuItems = [
    {
      title: 'orin.assist',
      path: '/chat',
      icon: <ChatIcon />,
      available: true
    },
    {
      title: 'orin.you',
      path: '/my-health-history',
      icon: <HistoryIcon />,
      available: status.fullSummaryCreated && !status.processingError,
      lastUpdate: status.lastStatusUpdate,
      secondary: getStatusMessage(status)
    },
    {
      title: 'orin.do',
      path: '/recommendations',
      icon: <RecommendIcon />,
      available: false,
      comingSoon: true
    }
  ];

  console.log('Health History menu item:', menuItems[1]);

  const handleLogout = () => {
    logout();
    navigate('/goodbye');
  };

  return (
    <Box className="side-panel">
      <Typography
        variant="h6"
        sx={{
          fontFamily: 'Courier New, Courier, monospace',
          mb: 2,
          fontWeight: 'bold',
          color: '#333',
          textAlign: 'left'
        }}
      >
        orin.health
      </Typography>
      <Divider sx={{ mb: 2 }} />
      <List>
        {menuItems.map((item, index) => (
          <ListItem
            key={index}
            button
            disabled={!item.available}
            selected={location.pathname === item.path}
            onClick={() => item.available && navigate(item.path)}
            sx={{
              fontFamily: 'Courier New, Courier, monospace',
              mb: 1,
              opacity: item.available ? 1 : 0.5,
              '&.Mui-selected': {
                backgroundColor: '#f5f5f5',
                '&:hover': {
                  backgroundColor: '#f0f0f0',
                }
              }
            }}
          >
            <ListItemIcon sx={{ minWidth: '40px' }}>
              {item.icon}
            </ListItemIcon>
            <ListItemText 
              primary={item.title}
              secondary={item.comingSoon ? '(Coming Soon)' : item.secondary}
              primaryTypographyProps={{
                fontFamily: 'Courier New, Courier, monospace',
              }}
              secondaryTypographyProps={{
                fontFamily: 'Courier New, Courier, monospace',
                fontSize: '0.8rem',
                color: item.comingSoon ? 'text.secondary' : undefined
              }}
            />
          </ListItem>
        ))}

        <Divider sx={{ my: 2 }} />
        
        <ListItem
          button
          onClick={handleLogout}
          sx={{
            fontFamily: 'Courier New, Courier, monospace',
            color: 'error.main',
            '&:hover': {
              backgroundColor: 'error.light',
            }
          }}
        >
          <ListItemIcon sx={{ minWidth: '40px', color: 'error.main' }}>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText 
            primary="Logout"
            primaryTypographyProps={{
              fontFamily: 'Courier New, Courier, monospace',
            }}
          />
        </ListItem>
      </List>
    </Box>
  );
};

export default UserPanel; 