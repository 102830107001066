import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { API_BASE_URL } from '../config';

const SeedRoundPDF = () => {
  useEffect(() => {
    // Redirect to the backend PDF endpoint
    window.location.href = `${API_BASE_URL}/seed-round`;
  }, []);

  return (
    <Box sx={{ width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      Loading PDF...
    </Box>
  );
};

export default SeedRoundPDF; 