import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
  Typography,
  CircularProgress,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { axiosInstance } from '../api/axiosConfig';
import GoogleLoginButton from './GoogleLoginButton';
import { useNavigate } from 'react-router-dom';

const StyledButton = styled(Button)({
  fontFamily: 'Courier New, Courier, monospace',
  border: '2px solid #333',
  color: '#333',
  padding: '10px 30px',
  margin: '10px',
  textTransform: 'lowercase',
  '&:hover': {
    backgroundColor: '#333',
    color: '#fff',
    border: '2px solid #333',
  },
});

const StyledTextField = styled(TextField)({
  '& .MuiInputBase-root': {
    fontFamily: 'Courier New, Courier, monospace',
    textTransform: 'lowercase'
  },
  '& .MuiInputLabel-root': {
    fontFamily: 'Courier New, Courier, monospace',
    textTransform: 'lowercase'
  },
  marginBottom: '1rem',
});

const RegisterModal = ({ open, onClose, onRegisterSuccess }) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState('');
  const [shouldClose, setShouldClose] = useState(false);

  // Reset state when modal opens
  React.useEffect(() => {
    if (open) {
      setShouldClose(false);
      setError('');
      setStatus('');
    }
  }, [open]);

  const handleEmailRegister = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError('passwords do not match');
      return;
    }

    setIsLoading(true);
    setError('');
    setStatus('creating account...');

    try {
      const registerResponse = await axiosInstance.post('/auth/register', {
        email,
        password,
      });

      if (registerResponse.data) {
        setStatus('Logging in...');
        try {
          const formData = new URLSearchParams();
          formData.append('username', email);
          formData.append('password', password);
          formData.append('grant_type', 'password');

          const loginResponse = await axiosInstance.post('/auth/login', 
            formData.toString(),
            {
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
              }
            }
          );
          
          if (loginResponse.data && loginResponse.data.access_token) {
            const userData = {
              id: loginResponse.data.user_id,
              email: email,
              access_token: loginResponse.data.access_token
            };
            
            await onRegisterSuccess(userData);
            onClose();
            navigate('/chat');
          } else {
            setError('Login failed after registration. Please try again.');
          }
        } catch (loginErr) {
          console.error('Login error:', loginErr);
          if (loginErr.response?.status === 422) {
            setError('Invalid login format. Please try again.');
          } else {
            setError(loginErr.response?.data?.detail || 'Login failed. Please try again.');
          }
        }
      }
    } catch (err) {
      console.error('Registration error:', err);
      if (err.code === 'ECONNABORTED') {
        setError('Registration is taking longer than usual due to slow connection. Please try again or use Google sign-in instead.');
      } else if (err.response?.status === 400 && err.response?.data?.detail === "Email already registered") {
        setError('This email is already registered. Please try logging in instead.'.toLowerCase());
      } else if (err.response?.status === 422) {
        const validationError = err.response?.data?.detail?.[0]?.msg;
        setError(validationError || 'Invalid input format. Please check your entries.');
      } else {
        setError(err.response?.data?.detail || 'Registration failed. Please try again.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  // Only actually close the modal when shouldClose is true
  const handleClose = () => {
    if (!isLoading) {
      onClose();
    }
  };

  return (
    <Dialog 
      open={open && !shouldClose} 
      onClose={handleClose}
      PaperProps={{
        sx: {
          fontFamily: 'Courier New, Courier, monospace',
          minWidth: '400px',
          '& .MuiTypography-root': {
            textTransform: 'lowercase'
          }
        }
      }}
    >
      <DialogTitle sx={{ textAlign: 'center', fontWeight: 'bold', textTransform: 'lowercase' }}>
        create account
      </DialogTitle>
      <DialogContent>
        <Box component="form" onSubmit={handleEmailRegister} sx={{ mt: 2 }}>
          {error && (
            <Typography color="error" sx={{ mb: 2, textTransform: 'lowercase' }}>
              {error}
            </Typography>
          )}
          <GoogleLoginButton onLoginSuccess={onRegisterSuccess} />
          <Box sx={{ my: 2, textAlign: 'center' }}>
            <Typography variant="body2" sx={{ textTransform: 'lowercase' }}>- or -</Typography>
          </Box>
          {status && !error && (
            <Typography 
              color="primary" 
              sx={{ 
                mb: 2,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 1,
                textTransform: 'lowercase'
              }}
            >
              <CircularProgress size={16} />
              {status}
            </Typography>
          )}
          <StyledTextField
            fullWidth
            label="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            disabled={isLoading}
            InputLabelProps={{
              sx: { textTransform: 'lowercase' }
            }}
          />
          <StyledTextField
            fullWidth
            label="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            disabled={isLoading}
            InputLabelProps={{
              sx: { textTransform: 'lowercase' }
            }}
          />
          <StyledTextField
            fullWidth
            label="confirm password"
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
            disabled={isLoading}
            InputLabelProps={{
              sx: { textTransform: 'lowercase' }
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', p: 3 }}>
        <StyledButton onClick={onClose} disabled={isLoading}>
          cancel
        </StyledButton>
        <StyledButton 
          onClick={handleEmailRegister}
          disabled={isLoading}
        >
          {isLoading ? <CircularProgress size={20} /> : 'register'}
        </StyledButton>
      </DialogActions>
    </Dialog>
  );
};

export default RegisterModal; 