// src/api/axiosConfig.js

import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_URL || 'https://api.orin.health';
const CHAT_URL = process.env.REACT_APP_CHAT_URL || 'https://chat.orin.health';

// Main API instance
export const axiosInstance = axios.create({
  baseURL: BASE_URL,
  timeout: 30000,  // Increased to 30 seconds
  headers: {
    'Content-Type': 'application/json',
  },
});

// Chat-specific instance
export const chatAxiosInstance = axios.create({
  baseURL: CHAT_URL,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add request interceptor to both instances
const addAuthInterceptor = (instance) => {
  instance.interceptors.request.use(
    (config) => {
      const userStr = localStorage.getItem('user');
      let token = null;
      if (userStr) {
        try {
          const user = JSON.parse(userStr);
          token = user.access_token;
        } catch (e) {
          console.error('Error parsing user from localStorage:', e);
        }
      }
      
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      // Increase timeout for registration endpoint
      if (config.url === '/auth/register') {
        config.timeout = 60000;  // 60 seconds for registration
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  // Add response interceptor
  instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;

      if (error.response?.status === 401 && 
          !originalRequest._retry && 
          !originalRequest.url?.includes('/auth/refresh')) {
        originalRequest._retry = true;

        try {
          // Always use main API instance for token refresh
          const response = await axiosInstance.post('/auth/refresh');
          const { access_token } = response.data;

          const userStr = localStorage.getItem('user');
          if (userStr) {
            const user = JSON.parse(userStr);
            const updatedUser = {
              ...user,
              access_token
            };
            localStorage.setItem('user', JSON.stringify(updatedUser));
          }

          originalRequest.headers.Authorization = `Bearer ${access_token}`;
          return instance(originalRequest);
        } catch (refreshError) {
          localStorage.removeItem('user');
          if (window.location.pathname !== '/') {
            window.location.href = '/';
          }
          return Promise.reject(refreshError);
        }
      }

      return Promise.reject(error);
    }
  );
};

// Apply interceptors to both instances
addAuthInterceptor(axiosInstance);
addAuthInterceptor(chatAxiosInstance);

export { BASE_URL, CHAT_URL };
