import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';

const TypingText = ({ text, delay = 50, variant = 'h3' }) => {
  const [displayText, setDisplayText] = useState('');
  const [wordIndex, setWordIndex] = useState(0);
  const wordsRef = React.useRef(text.split(' '));

  useEffect(() => {
    const words = wordsRef.current;
    let isCurrentlyTyping = true;

    const typeWord = async () => {
      if (wordIndex < words.length && isCurrentlyTyping) {
        const currentText = words.slice(0, wordIndex + 1).join(' ');
        const pauseTime = wordIndex === words.length - 1 ? 1000 : delay * 3;

        await new Promise(resolve => setTimeout(resolve, delay));
        if (isCurrentlyTyping) {
          setDisplayText(currentText);
        }
        
        await new Promise(resolve => setTimeout(resolve, pauseTime));
        if (isCurrentlyTyping) {
          setWordIndex(prev => prev + 1);
        }
      }
    };

    typeWord();

    return () => {
      isCurrentlyTyping = false;
    };
  }, [wordIndex, delay]);

  return (
    <Typography
      variant={variant}
      component="div"
      sx={{
        fontFamily: 'Courier New, Courier, monospace',
        whiteSpace: 'pre-wrap',
        minHeight: '3em',
        fontWeight: 'bold',
        textAlign: 'center',
        '&::after': {
          content: '"|"',
          animation: 'blink 1s step-end infinite',
          fontWeight: 'normal',
        },
        '@keyframes blink': {
          'from, to': { opacity: 1 },
          '50%': { opacity: 0 },
        },
      }}
    >
      {displayText}
    </Typography>
  );
};

export default TypingText; 